.form-limite {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 350px;
  height: 250px;
  border: 1px solid #f0eeee;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 1px 1px 10px 1px #f0eeee;
}

.limiteInput {
  background-color: #d3d3d3;
  border-radius: 10px;
  border: 1px solid #f0eeee;
  height: 50px;
  width: 150px;
  text-align: center;
}

.limit-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 20px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 20px;
}

.limit-slider:hover {
  opacity: 1;
}

.limit-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #065538;
  cursor: pointer;
  border-radius: 50%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.limit-slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background: #04aa6d;
  cursor: pointer;
}
