.group-login {
  width: 100%;
  margin-bottom: 32px;
}

.group-login .input-group {
  width: 100%;
  margin-bottom: 24px;
}

.login-div {
  width: auto;
  height: auto;
  margin-top: 80px;
  margin-left: 80px;
  padding: 32px;
}

.welcome-div {
  justify-content: flex-start;
  align-content: left;
  text-align: left;
  margin-bottom: 24px;
}

.corporativo-text {
  color: #0a1d6f;
  font-size: 24px;
  font-family: Inter;
  font-weight: 600;
  word-wrap: break-word;
}

.gray-text {
  color: #3f3f3f;
  font-size: 24px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.darkgray-text {
  color: #3f3f3f;
}

.first-access {
  margin-top: 50px;
}

.record-user-div {
  height: 22px;
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
  align-items: left;
}

.recor-user {
  color: #3f3f3f;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.record-user-div .switch {
  margin-left: 12px;
}

.lnr-eye {
  position: absolute;
  top: 40px;
  right: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 576px) {
  .login-div {
    margin-top: 12px;
    margin-left: 12px;
    padding: 16px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .login-div {
    margin-top: 12px;
    margin-left: 12px;
    padding: 16px;
  }
}
