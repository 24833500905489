.campos-fatura {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  width: 100%;
}

#eyeconf {
  position: absolute;
  top: 15px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.linhaLancamento {
  width: 100%;
  height: 100%;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: white;
  border-bottom: 1px #d9d9d9 solid;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}

.linhaLancamento-data {
  color: #737373;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
}

.linhaLancamento-descricao {
  color: #3f3f3f;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
}

.linhaLancamento-valor {
  color: #3f3f3f;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  text-transform: capitalize;
  word-wrap: break-word;
}

@media screen and (max-width: 576px) {
  .campos-fatura {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .campos-fatura {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  /* Estilos para tablets pequenos */
  .campos-fatura {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
  }
}
