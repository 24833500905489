.cartoes{
    overflow-x: scroll;
    height: 700px;
    width: 100%;
}

.cartoes ul li {
    list-style-type: none;
    margin: none;
    
}