

@media screen and (max-width: 576px) {
  /* Estilos para dispositivos móveis pequenos */
  .menu {
    width: 100%;
  }
  .menu-body {
    width: 100%;
    padding-top: 0px;
  }
  .menu-body-list {
    border: 0.95px #f0f0f0 solid;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
    border-radius: 10px;
    width: 100%;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  }

  .menu-item {
    padding: 13px;
    background: white;
    border-top: 1px #f2f3f7 solid;
    border-bottom: 1px #f2f3f7 solid;
    gap: 10px;
    display: inline-block;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    height: 72px;
    width: 25%;
  }

  .menu-item-top {
    border-top-left-radius: 50px;
  }
  .menu-item-bottom {
    border-top-right-radius: 50px;
  }
  .menu-item-text {
    color: #3f3f3f;
    font-size: 11px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
    width: 100%;
  }
  .hover-btn {
    display: none;
  }
  .hover-btn-close {
    display: hidden;
  }
  .hover-btn-icon-img {
    width: 24px;
    height: 24px;
    color: #0a1d6f;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  /* Estilos para dispositivos móveis médios */
  .menu {
    width: 100%;
  }
  .menu-body {
    width: 100%;
    padding-top: 0px;
  }
  .menu-body-list {
    border: 0.95px #f0f0f0 solid;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
    border-radius: 10px;
    width: 100%;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  }

  .menu-item {
    padding: 13px;
    background: white;
    border-top: 1px #f2f3f7 solid;
    border-bottom: 1px #f2f3f7 solid;
    gap: 10px;
    display: inline-block;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    height: 72px;
    width: 25%;
  }

  .menu-item-top {
    border-top-left-radius: 50px;
  }
  .menu-item-bottom {
    border-top-right-radius: 50px;
  }
  .menu-item-text {
    color: #3f3f3f;
    font-size: 11px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
    width: 100%;
  }
  .hover-btn {
    display: none;
  }
  .hover-btn-close {
    display: hidden;
  }
  .hover-btn-icon-img {
    width: 24px;
    height: 24px;
    color: #0a1d6f;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  /* Estilos para tablets pequenos */
  .menu {
    width: 100%;
  }
  .menu-body {
    width: 100%;
    padding-top: 0px;
  }
  .menu-body-list {
    border: 0.95px #f0f0f0 solid;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
    border-radius: 10px;
    width: 100%;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  }

  .menu-item {
    padding: 13px;
    background: white;
    border-top: 1px #f2f3f7 solid;
    border-bottom: 1px #f2f3f7 solid;
    gap: 10px;
    display: inline-block;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    height: 72px;
    width: 25%;
  }

  .menu-item-top {
    border-top-left-radius: 50px;
  }
  .menu-item-bottom {
    border-top-right-radius: 50px;
  }
  .menu-item-text {
    color: #3f3f3f;
    font-size: 11px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
    width: 100%;
  }
  .hover-btn {
    display: none;
  }
  .hover-btn-close {
    display: hidden;
  }
  .hover-btn-icon-img {
    width: 24px;
    height: 24px;
    color: #0a1d6f;
  }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
  /* Estilos para tablets grandes / desktops pequenos */
  .menu {
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    z-index: 1000;
  }
  .menu-body {
    padding-top: 32px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: flex;
  }
  
  .menu-body-list {
    border: 0.95px #f0f0f0 solid;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  
  .menu-item {
    padding: 13px;
    background: white;
    border-top: 1px #f2f3f7 solid;
    border-bottom: 1px #f2f3f7 solid;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    cursor: pointer;
    height: 72px;
  }
  
  .menu-item-top {
    border-top-right-radius: 10px;
  }
  .menu-item-bottom {
    border-bottom-right-radius: 10px;
  }
  
  .menu-item-icon {
    width: 24px;
    height: 24px;
    position: relative;
  }
  
  .menu-item-text {
    width: 251px;
    color: #3f3f3f;
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
    transition: max-height 0.15s ease-out;
    max-height: 100px;
    overflow: hidden;
    text-align: left;
  }
  
  .collapsed {
    display: none;
  }
  
  .hover-btn {
    padding: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: flex;
    margin: 10px;
  }
  
  .hover-btn-close {
    width: 28px;
    height: 28px;
    position: relative;
    transform: rotate(180deg);
    /*transform: rotate(180deg);
    transform-origin: 0 0;*/
    border-radius: 28px;
    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  
  .hover-btn-icon {
    width: 28px;
    height: 28px;
    left: 0px;
    top: 0px;
    position: absolute;
    background: white;
    border-radius: 32.5px;
    border: 1px white solid;
  }
  
  .hover-btn-icon-body {
    width: 18.67px;
    height: 18.67px;
    left: 29.5px;
    top: 35px;
    position: absolute;
    transform: rotate(180deg);
    transform-origin: 0 0;
  }
  
  .hover-btn-icon-img {
    width: 16px;
    height: 16px;
    left: 3.89px;
    top: 7px;
    position: absolute;
    color: #0a1d6f;
  }
}

@media screen and (min-width: 1201px) {
  /* Estilos para desktops grandes */
  .menu {
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    z-index: 1000;
  }
  .menu-body {
    padding-top: 32px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: flex;
  }
  
  .menu-body-list {
    border: 0.95px #f0f0f0 solid;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  
  .menu-item {
    padding: 13px;
    background: white;
    border-top: 1px #f2f3f7 solid;
    border-bottom: 1px #f2f3f7 solid;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    display: inline-flex;
    cursor: pointer;
    height: 72px;
  }
  
  .menu-item-top {
    border-top-right-radius: 10px;
  }
  .menu-item-bottom {
    border-bottom-right-radius: 10px;
  }
  
  .menu-item-icon {
    width: 24px;
    height: 24px;
    position: relative;
  }
  
  .menu-item-text {
    width: 251px;
    color: #3f3f3f;
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
    transition: max-height 0.15s ease-out;
    max-height: 100px;
    overflow: hidden;
    text-align: left;
  }
  
  .collapsed {
    display: none;
  }
  
  .hover-btn {
    padding: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: flex;
    margin: 10px;
  }
  
  .hover-btn-close {
    width: 28px;
    height: 28px;
    position: relative;
    transform: rotate(180deg);
    /*transform: rotate(180deg);
    transform-origin: 0 0;*/
    border-radius: 28px;
    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  
  .hover-btn-icon {
    width: 28px;
    height: 28px;
    left: 0px;
    top: 0px;
    position: absolute;
    background: white;
    border-radius: 32.5px;
    border: 1px white solid;
  }
  
  .hover-btn-icon-body {
    width: 18.67px;
    height: 18.67px;
    left: 29.5px;
    top: 35px;
    position: absolute;
    transform: rotate(180deg);
    transform-origin: 0 0;
  }
  
  .hover-btn-icon-img {
    width: 16px;
    height: 16px;
    left: 3.89px;
    top: 7px;
    position: absolute;
    color: #0a1d6f;
  }
}
