.credit-card {
  padding: 20px;
  color: #737373;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;  
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-items: center;
  display: inline-block;
  text-align: center;
}

.credit-card-number {
  margin-top: 20px;
  letter-spacing: 2px;
}

.credit-card-info {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
