.valid-div {
  width: 350px;
  margin-top: 80px;
  margin-left: 80px;
}

.msg {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  width: 100%;
}

.input-codigo {
  border-radius: 10px;
  width: 41.67px;
  height: 55px;
  text-align: center;
  background-color: #f2f3f7;
}

.traco{
  width: 15px;
  height: 55px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  color: #3F3F3F;
}

.codigo-div {
  display: inline-flex;
  gap: 1px;
  margin-top: 40px;
  margin-bottom: 50px;
}

.confira-o-codigo {
  color: #3f3f3f;
  font-size: 24px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
  text-align: left;
}

.confira {
  color: #3f3f3f;
  font-size: 24px;
  font-family: Inter;
  font-weight: 400;
  word-wrap: break-word;
}

.enviado {
  color: #3f3f3f;
  font-size: 24px;
  font-family: Inter;
  font-weight: 400;
  line-height: 30px;
  word-wrap: break-word;
}

.sms {
  color: #0a1d6f;
  font-size: 24px;
  font-family: Inter;
  font-weight: 400;
  line-height: 30px;
  word-wrap: break-word;
}

.digite-codigo{
  text-align: left;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 576px) {
  .valid-div {
    margin-top: 12px;
    margin-left: 12px;
    padding: 16px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .valid-div {
    margin-top: 12px;
    margin-left: 12px;
    padding: 16px;
  }
}
